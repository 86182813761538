@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-thin.eot?#iefix) format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-thin.woff) format("woff"),
    url(./fonts/proximanova/proximanova-thin.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-thin.svg#proximanova-thin) format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-light.eot?#iefix) format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-light.woff) format("woff"),
    url(./fonts/proximanova/proximanova-light.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-light.svg#proximanova-light) format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-regular.eot?#iefix) format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-regular.woff) format("woff"),
    url(./fonts/proximanova/proximanova-regular.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-regular.svg#proximanova-regular) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-bold.eot?#iefix) format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-bold.woff) format("woff"),
    url(./fonts/proximanova/proximanova-bold.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-bold.svg#proximanova-bold) format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-bold.eot?#iefix) format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-bold.woff) format("woff"),
    url(./fonts/proximanova/proximanova-bold.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-bold.svg#proximanova-bold) format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-extrabld.eot?#iefix) format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-extrabld.woff) format("woff"),
    url(./fonts/proximanova/proximanova-extrabld.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-extrabld.svg#proximanova-extrabld) format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-semibold.eot?#iefix) format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-semibold.woff) format("woff"),
    url(./fonts/proximanova/proximanova-semibold.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-semibold.svg#proximanova-semibold) format("svg");
    font-weight: 800;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-black.eot?#iefix) format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-black.woff) format("woff"),
    url(./fonts/proximanova/proximanova-black.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-black.svg#proximanova-black) format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: block;
}

html,
body {
    margin: 0 !important;
    padding: 0 !important;
    background: #edf1f7;
    color: #678293;
    font-weight: 400;
    font-size: 1rem;
    font-family: ProximaNova, Arial, sans-serif;
    line-height: 1.6;
}