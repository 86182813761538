.th {
    font-weight: bold;
    color: black;
    font-size: 16px;
}
.closedRow {
    background: #ebebeb;
    color: gray;
}
.newRow {
    background: #ffcf86;
}
.deletedRow {
    background: #ff6a7b;
}
.defaultRow {
    background: #fff;
}
.row:hover {
    background: inherit;
}