.b_header {
    width: 100%;
    height: 66px;
    display: flex;
    color: #222b45;
    box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, .1);
    /* padding: 15px 30px 13px; */
    background: #fff;
    position: fixed;
    z-index: 10;
    justify-content: space-between;
    align-items: center;
}

.b_logout {
    /* float: right; */
    text-transform: uppercase;
    font-size: 1rem;
    text-decoration: none;
    color: #333;
    letter-spacing: 0.1px;
    margin-top: 8px;
    font-weight: 600;
}

.b_logout:hover {
    color: #000;
    /* text-decoration: underline; */
}

.b_header__title {
    /* float: left; */
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
}

a.b_header__logo {
    margin-left: 30px;
    text-decoration: none;
    /* float: left; */
    color: #222b45;
    text-transform: uppercase;
}

.right {
    margin-left: auto;
    margin-right: 30px;
    display: flex;
    text-transform: uppercase;
    font-size: 14px;
    color: #333;
    letter-spacing: 0.1px;
    font-weight: 600;
    align-items: baseline;
}
.rate {
    margin: 0 10px;
}