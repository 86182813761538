.th {
  font-weight: bold;
  color: black;
  font-size: 16px;
}

/*@keyframes animation {*/
/*  0% {*/
/*    transform: translateX(-150%);*/
/*  }*/
/*  15% {*/
/*    transform: translateX(5%);*/
/*  }*/
/*  30% {*/
/*    transform: translateX(-5%);*/
/*  }*/
/*  45% {*/
/*    transform: translateX(3%);*/
/*  }*/
/*  60% {*/
/*    transform: translateX(-3%);*/
/*  }*/
/*  75% {*/
/*    transform: translateX(1%);*/
/*  }*/
/*  85% {*/
/*    transform: translateX(-1%);*/
/*  }*/
/*  100% {*/
/*    transform: translateX(0);*/
/*  }*/
/*}*/

/*.open_animation {*/
/*  animation: animation 1s ease !important;*/
/*  position: relative !important;*/
/*}*/
